// {/* <div className={`header_inner ${isMenuOpen ? 'header_inner-active' : ''}`}> */}
import React, { useState } from 'react';
import './header.css';
import logo from "../../assets/img/logos.svg";
import { Link } from 'react-router-dom';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {/* Полупрозрачный слой */}
      <div className={`overlay ${isMenuOpen ? 'overlay_active' : ''}`} onClick={closeMenu}></div>

      <header className="header">
        <div className="container">
          <div className="header_inner">
            <div className="header_logo">
              <img src={logo} alt="logo" className="logo" />
              <span>YIGROUP</span>
            </div>

            <nav className='menu'>
            <Link to="/" className="nav__link" onClick={closeMenu}>Главная</Link>
              <Link to="/about" className="nav__link" onClick={closeMenu}>О нас</Link>
              <Link to="/services" className="nav__link" onClick={closeMenu}>Сервисы</Link>
              <Link to="/projects" className="nav__link" onClick={closeMenu}>Проекты</Link>
              <a href="#Contact" className="nav__link" onClick={closeMenu}>Контакты</a>
            </nav>

            {isMenuOpen ? 
              <nav className={`nav ${isMenuOpen ? 'nav_active' : ''}`}>
                <Link to="/" className="nav__link" onClick={closeMenu}>Главная</Link>
                <Link to="/about" className="nav__link" onClick={closeMenu}>О нас</Link>
                <Link to="/services" className="nav__link" onClick={closeMenu}>Сервисы</Link>
                <Link to="/projects" className="nav__link" onClick={closeMenu}>Проекты</Link>
                <a href="#Contact" className="nav__link" onClick={closeMenu}>Контакты</a>
              </nav>
            : ""}
            

            <a 
              href="https://wa.me/+77015558517?text=Здравствуйте,%20я%20хочу%20заказать%20услугу" 
              className="header_btn" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Хотите заказать?
            </a>

            {/* Кнопка гамбургера */}
            <div className={`hamburger ${isMenuOpen ? 'is-active' : ''}`} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
