import React, { useState } from "react";
import "./form.css";

export default function Form() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [status, setStatus] = useState(""); 

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault(); 

    try {
      const response = await fetch("http://localhost:5000/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus("Заявка успешно отправлена!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        }); 
      } else {
        setStatus("Ошибка при отправке заявки.");
      }
    } catch (error) {
      console.error("Ошибка отправки данных:", error);
      setStatus("Сервер не доступен. Попробуйте позже.");
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form_item">
        <div>
          <label>Имя</label>
          <input
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            type="text"
          />
        </div>
        <div>
          <label>Email</label>
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            type="email"
          />
        </div>
      </div>
      <div>
        <label>Номер телефона</label>
        <input
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          type="tel"
        />
      </div>
      <div>
        <label>Сообщение</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className="form_textarea"
        ></textarea>
      </div>
      <button className="form_btn" type="submit">
        Отправить
      </button>
      {status && <p className="form_status">{status}</p>}
    </form>
  );
}
