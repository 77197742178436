import React from 'react';

import Main from '../../components/main/Main';
import About from '../../components/about/About';
import Innovate from '../../components/innovate/Innovate';
import Advantages from '../../components/advantages/Advantages';
import Video from '../../components/video/Video';
import Works from '../../components/works/Works';
import Contacts from '../../components/contacts/Contacts';

export default function HomePage() {
  return (
    <div>
      <Main />
      <About />
      <Innovate />
      <Advantages />
      <Works /> 
      <Video />
      <Contacts/>
    </div>
  )
}
