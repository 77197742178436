import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { aboutImage } from '../../components/images';


import './aboutPage.css';

export default function AboutPage() {
  return (
    <div className='About'>
      <section className="about-main">
        <div className="container">
            <div className="about-main_content">
                <div className="about-main_item">
                    <div className="about-main_item-ImgL">
                        <img src={aboutImage.mainImage1} alt="img" />
                    </div>
                    <div className="about-main_item-ImgS">
                        <img src={aboutImage.mainImage2} alt="img" />
                    </div>
                </div>
                <div className="about-main_item">
                    <div className="suptitle">About us</div>
                    <h1 className="subtitle about-main_title">Owner and investor with a reputation</h1>
                    <p className="about-main_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit sem, vestibulum sed leo eu, malesuada pharetra mauris. Integer rhoncus, eros vel euismod tempor, ipsum magna tristique nisi, quis ullamcorper enim magna eu orci. Sed semper ex quis semper aliquet. Cras hendrerit molestie sapien sed fermentum. Mauris dui tortor, viverra vel ultrices in, congue sed ex.</p>
                </div>
            </div>
        </div>
      </section>

    <section className="static">
      <div className="container">
            <div className="static_content">
                <div className="suptitle">Статистика</div>
                <div className="subtitle">Наши достижения</div>
                <div className="static_items">
                    <div className="static_item">   
                        <img src={aboutImage.expIcon} alt="icon" />
                        <div>
                            <span>&gt;17+</span>
                            <p>Опыта в сфере</p>
                        </div>
                    </div>
                    <div className="static_item">
                        <img src={aboutImage.clientIcon} alt="icon"/ >
                        <div>
                            <span>&gt;100</span>
                            <p>Положительных отывов</p>
                        </div>
                    </div>
                    <div className="static_item">
                        <img src={aboutImage.buildIcon} alt="icon" />
                        <div>
                            <span>&gt;30+</span>
                            <p>Реализованных проектов</p>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </section>

    <section className="partners">
      <div className="container">
            <div className="partners_title">Наши партнеры</div>
            <Swiper
                className='partners_swiper'
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={40}
                slidesPerView={3}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2000, 
                  disableOnInteraction: false, 
                }}
                loop={true}
                onSwiper={(swiper: any) => console.log(swiper)}
                
              >
                <SwiperSlide>
                  <img src={aboutImage.companyImage1} alt="company" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={aboutImage.companyImage2} alt="company" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={aboutImage.companyImage3} alt="company" />
                  </SwiperSlide>
                <SwiperSlide>
                  <img src={aboutImage.companyImage4} alt="company" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={aboutImage.companyImage5} alt="company" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={aboutImage.companyImage6} alt="company" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={aboutImage.companyImage7} alt="company" />
                </SwiperSlide>
              </Swiper>
      </div>
    </section>

    <section className="teams">
      <div className="container">
            <div className="subtitle">Наша команда</div>
            <div className="teams_content">
              
                {/* <div className="swiper TeamSwipper team_swiper">
                    <div className="swiper-wrapper">
                      
                      <div className="swiper-slide swiper-slide-team">
                        <img src={aboutImage.teamsPhoto1} alt="profile" />
                        <div className="team_descr">
                            <span>Эд Ширан</span>
                            <p>Генеральный директор</p>
                        </div>
                      </div>
                      <div className="swiper-slide swiper-slide-team">
                        <img src={aboutImage.teamsPhoto1} alt="profile" />
                        <div className="team_descr">
                            <span>Эд Ширан</span>
                            <p>Генеральный директор</p>
                        </div>
                      </div>
                      <div className="swiper-slide swiper-slide-team">
                        <img src={aboutImage.teamsPhoto1} alt="profile" />
                        <div className="team_descr">
                            <span>Эд Ширан</span>
                            <p>Генеральный директор</p>
                        </div>
                      </div>
                      <div className="swiper-slide swiper-slide-team">
                        <img src={aboutImage.teamsPhoto1} alt="profile" />
                        <div className="team_descr">
                            <span>Эд Ширан</span>
                            <p>Генеральный директор</p>
                        </div>
                      </div>
                      <div className="swiper-slide swiper-slide-team">
                        <img src={aboutImage.teamsPhoto1} alt="profile" />
                        <div className="team_descr">
                            <span>Эд Ширан</span>
                            <p>Генеральный директор</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div> */}
        </div>
      </div>
    </section>
      </div>
  )
}
