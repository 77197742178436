import React from 'react';

import './servicesPage.css';

import img1 from "../../assets/img/services-img-1.png";

export default function ServicesPage() {
  return (
    <div className='service'>
      <section className="services_main">
        <div className="containers">
          <div className="services_main_content">
            <div className="services_main_suptitle">Сервисы</div>
            <h2 className="services_main_subtitle">Создавая будущее, строим сегодня</h2>
            <div className="services_main_text">
            Мы предлагаем широкий спектр услуг в сфере проектирования, строительства и управления проектами. Наш опыт и профессионализм гарантируют высокое качество работы и надежный результат
            </div>
          </div>
        </div>
        <div className="services_overlay"></div>
      </section>

      <section className="services">
        <div className="containers">
          <div className="services_content">
            {/* Первый сервис */}
            <div className="services_items">
              <img src={img1} alt="img" />
              <div className="services_item">
                <div className="services_item-title">Электрификация и освещение</div>
                <div className="services_item-text">
                  Прокладка воздушных и кабельных линий электропередач, Строительство системы наружного освещения, Ремонт и обслуживание уличного и дворового освещения, Ремонт и обслуживание внутреннего освещения зданий и сооружений, Декоративная подсветка зданий и сооружений, Световое оформление вывесок и входной группы, Праздничное оформление, установка декораций и МАФ.
                </div>
                <div className='services_plains'>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                </div>
                <div className="more">Подробнее <span>&#8594;</span></div>
              </div>
            </div>

            {/* Второй сервис */}
            <div className="services_items">
              <img src={img1} alt="" />
              <div className="services_item">
                <div className="services_item-title">Строительство и ремонт</div>
                <div className="services_item-text">
                  Строительство, реконструкция, капитальный и текущий ремонт объектов жилищно-коммунального хозяйства и социальной сферы под ключ – полное сопровождение от стадии проектирования до сдачи в эксплуатацию, а также гарантийное и постгарантийное сервисное обслуживание, Ремонтно-строительные работы на высоте, обслуживание высотных зданий и сооружений, услуги промышленных альпинистов.
                </div>
                <div className='services_plains'>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                </div>
                <div className="more">Подробнее <span>&#8594;</span></div>
              </div>
            </div>

            {/* Третий сервис */}
            <div className="services_items">
              <img src={img1} alt="" />
              <div className="services_item">
                <div className="services_item-title">Благоустройство территорий</div>
                <div className="services_item-text">
                  Строительство и ремонт спортивных площадок, Строительство и ремонт детских игровых площадок, Благоустройство парков, скверов и рекреационных зон.
                </div>
                <div className='services_plains'>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                </div>
                <div className="more">Подробнее <span>&#8594;</span></div>
              </div>
            </div>

            {/* Четвертый сервис */}
            <div className="services_items">
              <img src={img1} alt="" />
              <div className="services_item">
                <div className="services_item-title">Полиграфические услуги</div>
                <div className="services_item-text">
                  Услуги поставки продукции с фирменными знаками и логотипом, Нанесение фирменных знаков и логотипов на различные материалы.
                </div>
                <div className='services_plains'>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                  <div className="services_plain">
                    <span>Name</span>
                    <p>Statistics</p>
                  </div>
                </div>
                <div className="more">Подробнее <span>&#8594;</span></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="callUs">
        <div className="containers">
          <div className="callUs_content">
            <div className="callUs_title">Позвоните нам</div>
            <div className="callUs_btns">
              <a href="#asd" className='callUs_btn-call'>Позвонить</a>
              <a href="#asd" className='callUs_btn-email'>Email</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
