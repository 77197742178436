import React from 'react';

import "./contacts.css";
import images from '../images';
import Form from '../form/Form';

export default function Contacts() {



  return (
    <section className="contacts">
        <div className="container">
            <div className="contacts_content">
                <div className="contacts_item">
                    <div className="contacts_suptitle">Контакты</div>
                    <div className="contacts_subtitle">Свяжитесь с нами</div>
                    <div className="contacts_info">
                        <img src={images.contactsIcon1} alt="call"/>
                        <div>
                            <p>Тел</p>
                            <a href="tel:+7-701-555-8517">+77015558517</a>
                        </div>
                    </div>
                    <div className="contacts_info">
                        <img src={images.contactsIcon2} alt="call"/>
                        <div>
                            <p>Email</p>
                            <a href="mailto:tolybekuly_ilyas@yigroup.kz">TOLYBEKULY_ILYAS@YIGROUP.KZ</a>
                        </div>
                    </div>
                    <div className="contacts_info">
                        <img src={images.contactsIcon3} alt="call"/>
                        <div>
                            <p>Тел</p>
                            <span>+77015558517</span>
                        </div>
                    </div>
                </div>

                <div className="contacts_item">
                    <Form />
                </div>

            </div>
        </div>
      </section>
  )
}
