import React from 'react';
import styles from './innovate.module.css';

import img1 from '../../assets/img/innovate-icon1.svg';
import img2 from '../../assets/img/innovate-icon2.svg';
import img3 from '../../assets/img/innovate-icon3.svg';

const Innovate = () => {
  return (
    <div className={styles.innovate}>
      <div className={styles.container}>
        <h2>Наши сервисы</h2>
        <div className={styles.innovate_content}>
          <div className={styles.innovate_item}>
            <div className={styles.icon}>
              <img src={img1} alt="icon" />
            </div>
            <h3>Featured Listing</h3>
            <p>ЭЛЕКТРОМОНТАЖНЫЕ РАБОТЫ, ПРОЕКТЫ УЛИЧНОГО И ГОРОДСКОГО ОСВЕЩЕНИЯ</p>
          </div>
          <div className={styles.innovate_item}>
            <div className={styles.icon}>
              <img src={img2} alt="icon" />
            </div>
            <h3>Available Properties</h3>
            <p>УСТРОЙСТВО ИНЖЕНЕРНЫХ СЕТЕЙ И СИСТЕМ, ВКЛЮЧАЮЩЕЕ КАПИТАЛЬНЫЙ РЕМОНТ И РЕКОНСТРУКЦИЮ</p>
          </div>
          <div className={styles.innovate_item}>
            <div className={styles.icon}>
              <img src={img3} alt="icon" />
            </div>
            <h3>In the News</h3>
            <p>РАБОТЫ ПО ОРГАНИЗАЦИИ СТРОИТЕЛЬСТВА, РЕКОНСТРУКЦИИ И КАПИТАЛЬНОГО РЕМОНТА СОЦИАЛЬНО-БЫТОВЫХ, ЖИЛЫХ И КОММЕРЧЕСКИХ ОБЪЕКТОВ</p>
          </div>
          <div className={styles.innovate_item}>
            <div className={styles.icon}>
              <img src={img3} alt="icon" />
            </div>
            <h3>In the News</h3>
            <p>РАБОТЫ ПО БЛАГОУСТРОЙСТВУ ДВОРОВ  И ТЕРРИТОРИИ, УСТАНОВКА ДЕТСКИХ ПЛОЩАДОК.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Innovate;
