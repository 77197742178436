import React from 'react';

import './works.css';

import icon1 from '../../assets/img/works-icon1.svg';

export default function Works() {
  return (
    <div className='works'>
      <div className="container">
        <h2 className="works_title">Наши преимущества</h2>
        <div className="works_content">
            <div className="works_item">
                <img src={icon1} alt="icon" className="works_item-img" />
                <div className="works_item-content">
                    <h4>Green grow</h4>
                    <p>Agriculture and farming are essential industries</p>
                    <a href="#projects" className="works_more">Подробнее  <span>&#8594;</span></a>
                </div>
            </div>
            <div className="works_item">
                <img src={icon1} alt="icon" className="works_item-img" />
                <div className="works_item-content">
                    <h4>Green grow</h4>
                    <p>Agriculture and farming are essential industries</p>
                    <a href="#projects" className="works_more">Подробнее  <span>&#8594;</span></a>
                </div>
            </div>
            <div className="works_item">
                <img src={icon1} alt="icon" className="works_item-img" />
                <div className="works_item-content">
                    <h4>Green grow</h4>
                    <p>Agriculture and farming are essential industries</p>
                    <a href="#projects" className="works_more">Подробнее  <span>&#8594;</span></a>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
