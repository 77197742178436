import React from 'react';
import './App.css';
import Header from './components/headers/Header';
import Footer from './components/footer/Footer';
import { Route, Routes } from 'react-router-dom';
import HomePage from './Pages/Home/HomePage';
import AboutPage from './Pages/About/AboutPage';
import ProjectPage from './Pages/Projects/ProjectPage';
import ServicesPage from './Pages/Services/ServicesPage';


function App() {
  return (
    <div className="App">
        <Header />
        <Routes>
          <Route path='/' index element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/projects' element={<ProjectPage />} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
