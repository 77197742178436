import React from 'react'

export default function Advantages() {
  return (
    <div className='advantages'>
      <div className="container">
        
      </div>
    </div>
  )
}
