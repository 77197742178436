import React from 'react';

import './main.css';

import img1 from "../../assets/img/mainimg-1.png";
import img2 from "../../assets/img/mainimg-2.png";
import img3 from "../../assets/img/mainimg-3.png";
import img4 from "../../assets/img/mainimg-4.png";
import img5 from "../../assets/img/mainimg-5.png";

export default function Main() {
  return (
    <div className='main'>
      <div className="container">
        <div className="main_content">
        <div className="main_item">
            <h1 className="main_suptitle">СТРОИМ СВЕТЛОЕ БУДУЩЕЕ</h1>
            <div className="main_subtitle">Мы верим, что каждый проект — это новая история, которую мы пишем вместе с вами.</div>

            <button className="main_btn">About the company</button>
        </div>
        <div className="main_item">
            <div className="main_rows">
                <img src={img1} alt="img" className="img-1" />
                <img src={img2} alt="img" className="img-2" />
                <img src={img3} alt="img" className="img-3" />
            </div>
            <div className="main_rows">
                <img src={img4} alt="img" className="img-4" />
                <img src={img5} alt="img" className="img-5" />
            </div>
        </div>
        </div>
        
      </div>
    </div>
  )
}
