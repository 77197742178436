const images = {
    contactsIcon1: require("../assets/img/call-icon.svg"),
    contactsIcon2: require("../assets/img/email-icon.svg"),
    contactsIcon3: require("../assets/img/call-icon.svg")
  };

export const aboutImage = {
    mainImage1: require('../assets/img/about-img1.png'),
    mainImage2: require('../assets/img/about-img2.png'),
    expIcon: require("../assets/img/exp-icon.svg"),
    clientIcon: require("../assets/img/client-ic.svg"),
    buildIcon: require("../assets/img/buil-icon.svg"),
    teamsPhoto1: require("../assets/img/teams-photo1.png"),
    companyImage1: require("../assets/img/company-1.png"),
    companyImage2: require("../assets/img/company-2.png"),
    companyImage3: require("../assets/img/company-3.png"),
    companyImage4: require("../assets/img/company-4.png"),
    companyImage5: require("../assets/img/company-5.png"),
    companyImage6: require("../assets/img/company-6.png"),
    companyImage7: require("../assets/img/company-7.png"),
    companyImage8: require("../assets/img/company-8.png")
}

export const projectImage = {
  project1: require('../assets/img/project-1.png')
}



export default images;