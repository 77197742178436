
import React, { useState } from "react";
import ReactPlayer from "react-player";
import './video.css';

export default function Video() {
  const [showVideo, setShowVideo] = useState(false);

  // Функция для отображения видео
  const doShowVideo = () => {
    setShowVideo(true); 
  };

  // Функция для скрытия видео (например, при клике на Overlay)
  const doHideVideo = () => {
    setShowVideo(false);
  };

  return (
    <div className='video'>
      <div className="video-content">
        {!showVideo ? (
          <div onClick={doShowVideo} className="play-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="35" viewBox="0 0 6 7" id="play">
            <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g fill="#fff" transform="translate(-347 -3766)">
                <g id="icons" transform="translate(56 160)">
                  <path id="play-[#1003]" d="M296.495 3608.573l-3.994-2.43c-.669-.408-1.501.107-1.501.926v4.862c0 .82.832 1.333 1.5.927l3.995-2.43c.673-.41.673-1.445 0-1.855"></path>
                </g>
              </g>
            </g>
          </svg>
          </div>
        ) : (
          <div className="video-overlay" onClick={doHideVideo}>
            <div className="video-player-container">
              <ReactPlayer 
                url="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" // Замените на ваш URL видео
                playing={true}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        )}
      </div>
      <div className="overlay" onClick={doHideVideo}></div>
    </div>
  );
}

