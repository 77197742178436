import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'; 
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';


import './projectPage.css';
import { projectImage } from '../../components/images';


export default function ProjectPage() {
  return (
    <div className='project'>

      <section className="project_main">
        <div className="containers">
          <div className="project_main_content">
            <div className="project_main_suptitle">Проекты</div>
              <h2 className="project_main_subtitle">Owner and investor with a reputation</h2>
              <div className="project_main_text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit sem, vestibulum sed leo eu, malesuada pharetra mauris. Integer rhoncus, eros vel euismod tempor, ipsum magna tristique nisi, quis ullamcorper enim magna eu orci. Sed semper ex quis semper aliquet. 
              </div>
          </div>
        </div>
        <div className="project_overlay"></div>
      </section>

      <section className="ourProjects">
        <div className="containers">
          <div className="ourProjects_title">Наши проекты</div>
          <div className="ourProjects_content">
          <Swiper
          className='project_swiper'
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      navigation
      autoplay
      pagination={{ clickable: true }}
      onSwiper={(swiper: any) => console.log(swiper)}
      // onSlideChange={}
    >
      <SwiperSlide className='project_swiper-item'>
        <img src={projectImage.project1} alt="img" />
        <div className="swiper_item-content">
          <div className="swiper_item-name">Project Surname</div>
          <div className="swiper_item-info">Info</div>
        </div>
      </SwiperSlide>
      <SwiperSlide className='project_swiper-item'>
        <img src={projectImage.project1} alt="img" />
        <div className="swiper_item-content">
          <div className="swiper_item-name">Project Surname</div>
          <div className="swiper_item-info">Info</div>
        </div>
      </SwiperSlide>
      <SwiperSlide className='project_swiper-item'>
        <img src={projectImage.project1} alt="img" />
        <div className="swiper_item-content">
          <div className="swiper_item-name">Project Surname</div>
          <div className="swiper_item-info">Info</div>
        </div>
      </SwiperSlide>
      <SwiperSlide className='project_swiper-item'>
        <img src={projectImage.project1} alt="img" />
        <div className="swiper_item-content">
          <div className="swiper_item-name">Project Surname</div>
          <div className="swiper_item-info">Info</div>
        </div>
      </SwiperSlide>
      <SwiperSlide className='project_swiper-item'>
        <img src={projectImage.project1} alt="img" />
        <div className="swiper_item-content">
          <div className="swiper_item-name">Project Surname</div>
          <div className="swiper_item-info">Info</div>
        </div>
      </SwiperSlide>
      <SwiperSlide className='project_swiper-item'>
        <img src={projectImage.project1} alt="img" />
        <div className="swiper_item-content">
          <div className="swiper_item-name">Project Surname</div>
          <div className="swiper_item-info">Info</div>
        </div>
      </SwiperSlide>
    </Swiper>
          </div>
        </div>
      </section>


      <div className="callUs callUs-project">
        <div className="containers">
          <div className="callUs_content">
            <div className="callUs_title">Позвоните нам</div>
            <div className="callUs_btns">
              <a href="#asd" className='callUs_btn-call'>Позвонить</a>
              <a href="#asd" className='callUs_btn-email'>Email</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
