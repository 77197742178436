import React from 'react';

import './about.css';

import img from '../../assets/img/about.png';

export default function About() {
  return (
    <div className='about'>
      <div className="container">
        <div className="about_content">
            <div className="about_item">
                <img className='about_img' src={img} alt="about" />
            </div>
            <div className="about_item">
                <div className="about_title">About us</div>
                <div className="about_subtitle">Owner and investor with a reputation</div>
                <div className="about_text">ТОО «YI GROUP» БЫЛО ОСНОВАНО В 2007 ГОДУ. ИМЕЕТСЯ ГОСУДАРСТВЕННАЯ ЛИЦЕНЗИЯ ПО СТРОИТЕЛЬСТВУ – 2 КАТЕГОРИИ. ПРОФЕССИОНАЛЬНЫЙ УРОВЕНЬ УПРАВЛЕНИЯ СТРОИТЕЛЬСТВОМ, ИСПОЛЬЗОВАНИЕ ИННОВАЦИОННЫХ ИНЖЕНЕРНЫХ РЕШЕНИЙ И ТЕХНОЛОГИЙ СТРОИТЕЛЬСТВА, ОПЫТНЫЙ ПЕРСОНАЛ И СОВРЕМЕННАЯ МАТЕРИАЛЬНО-ТЕХНИЧЕСКАЯ БАЗА ОБЕСПЕЧИВАЮТ КОМПАНИИ УСПЕХ НА СТРОИТЕЛЬНОМ РЫНКЕ КРУПНЫХ МЕГАПОЛИСОВ, А ТАКЖЕ ЗАПАДНЫХ И ЮЖНЫХ ОБЛАСТЕЙ РЕСПУБЛИКИ КАЗАХСТАН.</div>
                <div className="about_btn">Подробнее</div>
            </div>
        </div>
      </div>
    </div>
  )
}
